// in ./ResetViewsButton.js
import React, { Fragment, useState, useEffect } from "react"
import { Button, useUpdateMany, useRefresh, useNotify, useUnselectAll, useTranslate } from "react-admin"
import styled from "styled-components"
import requests from '../../core/requests'
import Alert from '../common/Alert'
import { Slide, DialogTitle, DialogContent, DialogActions, Dialog, Snackbar, MenuItem, Select } from '@mui/material'

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  min-width: 400px;
`
const StyledSelect = styled(Select)`
    min-width: 120px
`
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
const settings = localStorage.getItem("settings")
const { upload_orders_file } = JSON.parse(settings) || {}
const UpdateStatusButton = ({ selectedIds, orders }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState("")
  const [order, setOrder] = useState('')
  const refresh = useRefresh()
  const notify = useNotify()
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const [sendMessageBtnDisabled, setSendMessageBtnDisabled] = useState(false)
  const [snackbar, setSnackbar] = useState({})
  const unselectAll = useUnselectAll()
  const [updateMany] = useUpdateMany(
    "orders",
    { ids: selectedIds, data: { status } },
    {
      onSuccess: () => {
        refresh()
        notify(translate("customRoot.upload.successMessage"), { type: "success" })
        unselectAll("orders")
      },
      onFailure: error => notify(translate("customRoot.upload.failureMessage"), "warning")
    }
  )

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    updateMany()
    setOpen(false)
  }

  const handleStatusChange = val => {
    setStatus(val.target.value)
  }

  const openSnackbar = (message, severity) => {
    setOpenSnackbar(true)
    setSnackbar({ message: message, severity: severity })
  }

  const setContinueBtnDisabledInTime = async () => {
    setInterval(() => {
      setSendMessageBtnDisabled(false)
    }, 5000)
  }

  const sendSmsToCharge = async () => {
    try {
      await requests.sendSmsToCharge(order._id, order.fields.phone)
      openSnackbar(translate('message_sent_successfully'), 'success')
    } catch (err) {
      openSnackbar(translate('requestResult.error'), 'error')
    }
    setSendMessageBtnDisabled(true)
    await setContinueBtnDisabledInTime()
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackbar(false)
  }

  useEffect(() => {
    if (Object.keys(orders).length !== 0 && selectedIds.length) {
      setOrder(orders[selectedIds])
    }
  }, [orders, selectedIds]);


  return (
    <Fragment>
      {!upload_orders_file && <Button label='customRoot.orders.modal.update_status_button' onClick={handleClick} />}
      {(order?.status == "הזמנה חדשה" || order?.status == "הודעה נשלחה בהצלחה" || order?.status == "כשלון בשליחת הודעה") &&
        <Button disabled={sendMessageBtnDisabled} label='customRoot.orders.modal.send_sms_to_charge' onClick={sendSmsToCharge} />
      }
      <Dialog open={open} onClose={handleDialogClose} TransitionComponent={Transition}>
        <FormContainer>
          <DialogTitle id='form-dialog-title'>{translate('customRoot.orders.modal.update_status_button')}</DialogTitle>
          <DialogContent>
            <StyledSelect labelId='demo-simple-select-label' id='demo-simple-select' value={status} onChange={val => handleStatusChange(val)}>
              <MenuItem value={"הזמנה חדשה"}>הזמנה חדשה</MenuItem>
              <MenuItem value={"הזמנה התקבלה"}>הזמנה התקבלה</MenuItem>
              <MenuItem value={"הזמנה בטיפול"}>הזמנה בטיפול</MenuItem>
              <MenuItem value={"הזמנה ממתינה לאיסוף"}>הזמנה ממתינה לאיסוף</MenuItem>
              <MenuItem value={"הזמנה נמסרה"}>הזמנה נמסרה</MenuItem>
              <MenuItem value={"הזמנה בוטלה"}>הזמנה בוטלה</MenuItem>
            </StyledSelect>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleDialogClose} color='primary' label='customRoot.orders.modal.cancel' />
            <Button onClick={handleConfirm} color='primary' label='customRoot.orders.modal.confirm' />
          </DialogActions>
        </FormContainer>
      </Dialog>
      <Snackbar open={isOpenSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Fragment>
  )
}

export default UpdateStatusButton
