import * as React from 'react';
import { styled as MuiStyled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions } from "@mui/material"
import { version } from "../../package.json"
import styled from "styled-components";
import relNotes from './../ReleaseNotes.txt';
import { useTranslate } from "react-admin"
import { IS_DEV_APP_ENV } from "../tools/utils"

const BootstrapDialog = MuiStyled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const Version = styled.span`
  body[dir='rtl'] & {
    left: -1px;
    right: unset;
    border-radius: 0 5px 5px 0;
  }
  border-radius: 5px 0 0 5px;
  position: fixed;
  bottom: 15px;
  right: -1px;
  left: unset;
  font-size: 12px;
  padding: 5px;
  border: 1px solid white;
  color: white;
  background: ${IS_DEV_APP_ENV ? "#4a0001" : '#2979FF'};
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;

export const ReleaseNotesDialog = () => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const handleClickOpen = () => {
    fetch(relNotes)
      .then(r => r.text())
      .then(text => {
        setNotes(text);
      });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Version onClick={handleClickOpen}>v{version}</Version>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={0}
      >
        <DialogTitle>{`${translate("customRoot.myMenu.releaseNotes")} v${version}`}</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle2" gutterBottom>
            <pre>{notes}</pre>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate("ra.action.close")}</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
