import React from 'react'
import {Alert as MuiAlert } from '@mui/material'
import styled from 'styled-components'

const StyledMuiAlert = styled(MuiAlert)`
  flex-direction: row-reverse;
`

const Alert = React.forwardRef(function Alert(props, ref) {
    return <StyledMuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default Alert