import React, { useState, useEffect } from "react"
import { FileField, FileInput, SimpleForm, useDataProvider, SelectInput, Loading, useTranslate, Toolbar, SaveButton, AutocompleteInput } from "react-admin"
import GetAppIcon from '@material-ui/icons/GetApp';
import { default as MuiButton } from '@mui/material/Button'
import settings from "../../tools/settings"
import { default as MuiAlert } from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import authProvider from "../../core/authProvider"
import styled from "styled-components"
import '../css/mui.css'
import { FormHelperText } from "@material-ui/core"

const apiUrl = settings.apiUrl
const token = localStorage.getItem("token")
const options = {
  headers: new Headers({
    Accept: "application/json"
  })
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #c8c8c8;
  border-style: dashed;
  background-color: #f0f0f0;
  color: #bdbdbd;
  outline: none;
  cursor: grab;
  transition: border 0.24s ease-in-out;
`

const Row = styled.div`
  width: 100%;
  display: flex;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 1rem;
  ${props => props.sx ?? ''}
`

const StyledLoader = styled(Loading)`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 20em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const StyledMuiAlert = styled(MuiAlert)`
  flex-direction: row-reverse;
`

const Explanation = styled.div`
  font-size: 24px;
  text-align: center;
`

const DownloadText = styled.a`
  color: #2196f3;
  text-decoration: none;
`

const CButton = styled(MuiButton)`
  color: #2196f3;
  padding: 2px;
  margin-right: 10px;
`

const TextContainer = styled.div`
  color: red;
  font-size: 24px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
`
const TextDefault = styled.p`
  color: black;
`
const TextActive = styled.p`
  color: red;
`

const CustomSelectInput = styled(SelectInput)`
  width: 256px;
  margin-left: 100px;
`

const FileUpload = ({ permissions, ...props }) => {
  const translate = useTranslate()
  const [orgChoices, setOrgChoice] = useState([])
  const [sysChoices, setSysChoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState({})
  const [isValid, setValid] = useState(false)

  const fetchOrgChoices = async () => {
    try {
      options.headers.set("Authorization", `Bearer ${token}`)
      const response = await fetch(`${apiUrl}/organizations`, options)
      const {status} = response;
      if (401 === status || 403 === status) {
        await authProvider.logout()
        window.location.replace("/login")
      }
      const data = await response.json()
      const organizations = data.data.map(org => ({
        id: org.id,
        name: org.display_name
      })).sort((a, b) => a.name.localeCompare(b.name))
      setOrgChoice(organizations)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSystemChoices = async value => {
    try {
      options.headers.set("Authorization", `Bearer ${token}`)
      const response = await fetch(`${apiUrl}/systems?filter=%7B%22org_id%22%3A${value}%7D`, options)
      const data = await response.json()
      setSysChoices(data.data.sort((a, b) => a.system_name.localeCompare(b.system_name)))
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchOrgChoices()
  }, [])
  const dataProvider = useDataProvider()
  
  const handleSave = async file => {
    try {
      setLoading(true)
      const result = await dataProvider.CREATE("upload", file)
      if (result.data) {
        setMessage({ message: translate("customRoot.upload.successMessage"), severity: "success" })
        setOpen(true)
        setLoading(false)
      } else {
        setMessage({ message: result.error, severity: "error" })
        setOpen(true)
        setLoading(false)
      }
    } catch (error) {
      setOpen(true)
      setMessage({ message: translate("customRoot.upload.failureMessage"), severity: "error" })
      setLoading(false)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <StyledMuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  const PostSaveButton = props => <SaveButton {...props} disabled={!isValid} />
  const DownloadButton = () => <CButton>
      <GetAppIcon />
      <DownloadText href="template.xlsx" download>
        {translate('customRoot.upload.templateText')}
      </DownloadText>
    </CButton>

  const validateUserCreation = (values) => {
    const {org_id, file, system_id} = values
    setValid(org_id && file && system_id)
  };
  
  return (
    <div>
      <Explanation>{translate("customRoot.upload.explainText")}</Explanation>
      <TextContainer>
        <TextActive>name</TextActive>|<TextActive>category</TextActive>|<TextDefault>description</TextDefault>|<TextDefault>sort_order</TextDefault>|<TextDefault>image</TextDefault>|<TextActive>price</TextActive>|<TextDefault>discount</TextDefault>|<TextDefault>weight</TextDefault>|<TextDefault>quantity</TextDefault>|<TextDefault>barcode</TextDefault>|<TextDefault>is_active</TextDefault>
      </TextContainer>

      <SimpleForm title='Uploads' onSubmit={handleSave} validate={validateUserCreation} toolbar={<Toolbar><PostSaveButton /><DownloadButton /></Toolbar>}>
        {loading && <StyledLoader loadingPrimary='ra.page.loading' loadingSecondary='customRoot.upload.loading' />}
        <Row>
          <Column sx={{ width: '30%' }}>
            <AutocompleteInput
              source="org_id"
              className="autocompeleteMekome"
              label='resources.organizations.singular'
              optionText='name'
              optionValue="id"
              choices={orgChoices}
              onChange={(value) => {
                fetchSystemChoices(value)
              }}
            />
          </Column>
          <Column>
            <div>
              <TextActive>{translate("customRoot.upload.mandatoryText")}</TextActive>
              <TextDefault>{translate("customRoot.upload.noMandatoryText")}</TextDefault>
            </div>
          </Column>
        </Row>
        <Row>
          <Column sx={{ width: '30%' }}>
            <AutocompleteInput
              source="system_id"
              сlassName="autocompeleteMekome"
              label='customRoot.organizations.list.system'
              optionText='system_name'
              choices={sysChoices}
              optionValue="id"
            />
          </Column>
        </Row>
        <Row>
          <Column sx={{ width: '30%' }}>
            <Container>
              <FileInput label='customRoot.upload.upload_label' source='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'>
                <FileField source='src' title='title' />
              </FileInput>
            </Container>
            <FormHelperText style={{textAlign: "right"}} error >{translate('customRoot.upload.warningMessage')}</FormHelperText>
          </Column>
        </Row>
      </SimpleForm>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={message.severity}>
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default FileUpload
