import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { useTranslate } from "react-admin"
import styled from 'styled-components'

const useStyles = makeStyles({
  table: {
    // minWidth: 550
  }
})

const TableCellBold = styled(TableCell)`
  font-weight: bold;
`

const ItemList = props => {
  const translate = useTranslate();
  const classes = useStyles()
  const { items, remarks } = props.record
  const totalPrice = items?.reduce((a, { price, quantity }) => a + price * quantity, 0).toFixed(2) || 0
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('customRoot.orders.items.category')}</TableCell>
            <TableCell>{translate('customRoot.orders.items.item_name')}</TableCell>
            <TableCell>{translate('customRoot.orders.items.quantity')}</TableCell>
            <TableCell>{translate('customRoot.orders.items.price')}</TableCell>
            <TableCell>{translate('customRoot.orders.items.remarks')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, idx) => (
            <TableRow hover key={idx}>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>{row.remarks}</TableCell>
            </TableRow>
          ))}
          <TableRow hover>
            <TableCell colSpan={2} />
            <TableCell>{translate('customRoot.orders.items.total')}</TableCell>
            <TableCell colSpan={2}>{totalPrice}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>{translate('customRoot.orders.items.order_remarks')}</TableCell>
            <TableCellBold colSpan={4}>{remarks}</TableCellBold>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
ItemList.defaultProps = {
  data: {},
  ids: []
}
export default ItemList
