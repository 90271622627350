import React, { useState } from "react"
import { FileField, FileInput, SimpleForm, useDataProvider, Loading, useTranslate } from "react-admin"
import { default as MuiAlert } from "@mui/material/Alert"
import { Snackbar } from "@mui/material"
import styled from "styled-components"

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #c8c8c8;
  border-style: dashed;
  background-color: #f0f0f0;
  color: #bdbdbd;
  outline: none;
  cursor: grab;
  transition: border 0.24s ease-in-out;
`

const StyledLoader = styled(Loading)`
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 20em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const StyledMuiAlert = styled(MuiAlert)`
  flex-direction: row-reverse;
`

const OrdersUpload = ({ permissions, ...props }) => {
  const translate = useTranslate()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState({})

  const dataProvider = useDataProvider()

  const handleSave = async file => {
    try {
      setLoading(true)
      const update = await dataProvider.CREATE("orders_upload", file)
      if (update.data) {
        setOpen(true)
        setMessage({ message: translate("customRoot.upload.successMessage"), severity: "success" })
        setLoading(false)
        return
      }
      setOpen(true)
      setMessage({ message: translate("customRoot.upload.failureMessage"), severity: "error" })
      setLoading(false)
    } catch (error) {
      setOpen(true)
      setMessage({ message: error.body.data, severity: "error" })
      setLoading(false)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <StyledMuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })
  return (
    <div>
      <SimpleForm title='Uploads' save={handleSave}>
        {loading && <StyledLoader loadingPrimary='ra.page.loading' loadingSecondary='customRoot.upload.loading' />}
        <Container>
          <FileInput label='customRoot.upload.upload_label' source='file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'>
            <FileField source='src' title='title' />
          </FileInput>
        </Container>
      </SimpleForm>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.severity}>
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default OrdersUpload
