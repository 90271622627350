import React from 'react'
import { SimpleForm, TextInput, required, useNotify, useTranslate, Create } from 'react-admin'
import { Navigate, useNavigate, useLocation  } from 'react-router-dom'

const CustomerCreate = (props) => {
	const location = useLocation();
	const { org_id, system_id } = location.state || {}
	const translate = useTranslate()
	const navigate = useNavigate();
	const notify = useNotify()

	const onSuccess = () => {
		notify(translate("customRoot.upload.successMessage"), { type: "success" })
		navigate('/customers');
	  };
	
	
	if (!system_id) return <Navigate to='/customers' />
	
  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
			<SimpleForm>
				{org_id && <TextInput source='org_id' label='resources.organizations.singular' validate={required()} defaultValue={org_id} disabled />}
				{system_id && <TextInput source='system_id' label='resources.systems.name' validate={required()} defaultValue={system_id} disabled />}
				<TextInput source='attributes.budget_number' label='customRoot.customers.budget_number' />
				<TextInput source='attributes.firstname' label='customRoot.customers.firstname' validate={required()} />
				<TextInput source='attributes.lastname' label='customRoot.customers.lastname' validate={required()} />
				<TextInput source='attributes.phone' label='customRoot.customers.phone' />
				<TextInput source='attributes.email' label='customRoot.customers.email' />
			</SimpleForm>
		</Create>
  )
}

export default CustomerCreate
