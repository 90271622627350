import React from 'react'
import styled from 'styled-components'
import { useRecordContext } from "react-admin"

const Wrap = styled.div`
  width: 100%;
`

const Fields = styled.div`
  display: flex;
  align-content: flex-end;
  flex-direction: column;
`

const Field = styled.div`
  direction: ltr;
  display: flex;
  justify-content: space-between;
  max-width: 350px;
`

const FieldsField = ({ source }) => {
  const record = useRecordContext();

  if(!record[source]) {
    record[source] = {}
  }

  const items = Object.entries(record[source])
  
  return (
    <Wrap>
      <Fields>
        {items.map(([key, value]) => typeof value !== 'object' ? <Field key={key}><b>{key}:</b> {value}</Field> : null)}
      </Fields>
    </Wrap>
  )
}

export default FieldsField
