import decodeJwt from "jwt-decode"
import settings from "../tools/settings"

const apiUrl = settings.apiUrl

export default {
  
  login: async ({ username, password }) => {
    
    const request = new Request(`${apiUrl}/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    })

    const response = await fetch(request)
    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText)
    const { data, settings } = await response.json()
    const decodedToken = decodeJwt(data);
    localStorage.setItem('token', data);
    localStorage.setItem('settings', JSON.stringify(settings));
    localStorage.setItem('permissions', decodedToken.role);
    window.location.replace("/")
  },
  
  logout: () => {
    localStorage.removeItem("permissions")
    localStorage.removeItem("token")
    return Promise.resolve();
  },

  checkError: params => {
    if (401 === params.status || 403 === params.status) {
      localStorage.removeItem("permissions")
      localStorage.removeItem("token")

      return Promise.reject()
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
  },
  
  getPermissions: () => {
    const role = localStorage.getItem("permissions")
    return role ? Promise.resolve(role) : Promise.reject()
  },

  getSettings: () => {
    const settings = localStorage.getItem("settings")
    return settings ? Promise.resolve(settings) : Promise.reject()
  },
  
  getToken: () => localStorage.getItem("token")
  
}
