// in src/MyLayout.js
import React from 'react';
import { Layout, AppBar, Logout } from 'react-admin';
import Menu from './Menu';
import { IS_DEV_APP_ENV } from "../tools/utils"

const MyAppBar = (props) => <AppBar {...props} style={{ backgroundColor: IS_DEV_APP_ENV ? "#4a0001" : '#2979FF', color: 'white' }}  />

const MyLayout = (props) => <Layout {...props}  menu={Menu}  appBar={MyAppBar} />;

export default MyLayout;
