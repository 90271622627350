import { stringify } from 'query-string'
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE_MANY,
  UPDATE_MANY,
  fetchUtils
} from 'react-admin'

import settings from '../tools/settings'
import authProvider from './authProvider'

const apiUrl = settings.apiUrl

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */

export const addUploadFeature = requestHandler => (type, resource, params) => {
  const d = params.data ? params : { data: params }
  if (d.data?.file?.rawFile instanceof File) {
    d.data.file = d.data.file.rawFile
    
    const form = new FormData()
    for (let key in d.data) {
      form.append(key, d.data[key])
    }
    return requestHandler(type, resource, { id: d.id, data: form })
  }
  return requestHandler(type, resource, params)
}

export default async (type, resource, params) => {

  let url = ''
  const token = authProvider.getToken()
  const options = {
    headers: new Headers({
      Accept: 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {})
    })
  }

  const { id } = params
  const resultStorageByID = JSON.parse(localStorage.getItem(id));
  const resultStorageByResource = JSON.parse(localStorage.getItem(resource));

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination
      const {  order } = params.sort
      let { field } = params.sort
      if(resource === "items" || resource === "orders" || resource === "customers" ) {
        field = "_id"
      }

      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([page, perPage]),
        filter: JSON.stringify(params.filter)
      }
      options.method = 'GET'
      url = `${apiUrl}/${resource}?${stringify(query)}`
      break
    }
    
    case GET_ONE:
      if (resource == 'order_settings') {
        const { org_id, system_id } = params.meta
        url = `${apiUrl}/${resource}/${id}?filter=${encodeURI(JSON.stringify({ org_id, system_id }))}` 
      } else {
        url = `${apiUrl}/${resource}/${id}`
        if(resultStorageByID) {
          const filter_query = {org_id: resultStorageByID.org_id, system_id: resultStorageByID.system_id}
          url += `?filter=${encodeURI(JSON.stringify(filter_query))}`
        }
      }
      break
    case CREATE:
      if (params.data instanceof FormData) {
        options.headers.delete('Content-Type')
        options.body = params.data
      } else {
        options.body = JSON.stringify(params.data)
        options.headers.set('Content-Type', 'application/json')
      }
      url = `${apiUrl}/${resource}`
      options.method = 'POST'
      break
    case UPDATE:
      console.log(params)
      if (params.data instanceof FormData) {
        options.headers.delete('Content-Type')
        options.body = params.data
      } else {
        options.headers.set('Content-Type', 'application/json')
        options.body = JSON.stringify(params.data)
      }
      url = `${apiUrl}/${resource}/${id}`
      options.method = 'PUT'
      break
    case UPDATE_MANY:
      const query = {
        filter: JSON.stringify({ id: params.ids })
      }
      url = `${apiUrl}/${resource}?${stringify(query)}`
      options.method = 'PATCH'
      options.body = JSON.stringify(params.data)
      break
    case DELETE:
      const { org_id } = params.previousData
      const { system_id } = params.previousData
      const delete_query = { org_id: resultStorageByID?.org_id || org_id, system_id: resultStorageByID?.system_id || system_id }
      url = `${apiUrl}/${resource}/${params.id}?filter=${encodeURI(JSON.stringify(delete_query))}`
      
      options.method = 'DELETE'
      break
    case DELETE_MANY:
      let delete_query_params = {}
      
      if(resultStorageByResource) {
        delete_query_params = {
          filter: JSON.stringify({ items: params.ids, system_id: resultStorageByResource.system_id, org_id: resultStorageByResource.org_id })
        }
      } else {
        delete_query_params = {
          filter: JSON.stringify({ id: params.ids })
        }
      }
      url = `${apiUrl}/${resource}?${stringify(delete_query_params)}`
      options.method = 'DELETE'
      break
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      }
      url = `${apiUrl}/${resource}?${stringify(query)}`
      break
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([
          (page - 1) * perPage,
          page * perPage - 1
        ]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: id
        })
      }
      url = `${apiUrl}/${resource}?${stringify(query)}`
      break
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }
  
  const { fetchJson } = fetchUtils
  try {
    const response = await fetchJson(url, options)
    return response.json
  } catch (error) {
    return {data: null, error: error.body.error}
  }
};
