import React, {  } from "react"
import { useNavigate  } from 'react-router-dom'
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  email,
  useNotify, 
  useTranslate
} from "react-admin"

const validateEmail = email();

const OrgCreate = props => {
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/organizations');
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <TextInput source='org_name' label='customRoot.organizations.list.org_name' />
        <TextInput source='display_name' label='customRoot.organizations.list.display_name' />
        <BooleanInput label="customRoot.organizations.list.active" source="active" />
        <ArrayInput source='systems'>
          <SimpleFormIterator disableClear={true}>
            <TextInput source='system_id' label='customRoot.users.list.id' />
            <TextInput source='system_name' label='customRoot.organizations.list.display_name' />
            <TextInput source='email' label='customRoot.users.list.email' validate={validateEmail} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
export default OrgCreate
