import React from "react"
import { makeStyles } from "@mui/styles"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { useTranslate } from "react-admin"

const useStyles = makeStyles({
  table: {
    // minWidth: 550
  }
})

const ItemList = props => {
  const translate = useTranslate();
  const classes = useStyles()
  const { systems } = props.record
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('customRoot.organizations.list.sys_id')}</TableCell>
            <TableCell>{translate('customRoot.organizations.list.display_name')}</TableCell>
            <TableCell>{translate('customRoot.users.list.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {systems.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell>{row.system_id}</TableCell>
              <TableCell>{row.system_name}</TableCell>
              <TableCell>{row.email}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
ItemList.defaultProps = {
  data: {},
  ids: []
}
export default ItemList
