export default {
  resources: {
    users: {
      name: "משתמשים"
    },
    organizations: {
      name: "ארגונים",
      singular: "ארגון"
    },
    orders: {
      name: "הזמנות"
    },
    items: {
      name: "פריטים"
    },
    systems: {
      name: "מערכת",
    },
    order_settings: {
      name: "הזמנת תצורה",
    },
    customers: {
      name: "לקוחות",
    }
  },
  ra: {
    configurable: {
      customize: ""
    },
    report_pos_system_admin: {
      columns: {
        created_at: 'תאריך ושעת ההזמנה',
        org_name: 'שם הארגון',
        system_name: 'שם המערכת',
        order_number: 'מספר הזמנה',
        customer_name: 'שם',
        phone: 'טלפון',
        email: 'דוא״ל',
        budget_number: 'מספר תקציב /חשבון',
        address: 'כתובת',
        order_type: 'סוג הזמנה',
        status: 'סטטוס',
        total_sum: 'סה״כ לחיוב',
        order_remarks: 'הערות הזמנה',
        category: 'קטגוריה',
        item: 'פריט',
        price: 'מחיר',
        quantity: 'כמות',
        item_remarks: 'הערות פריט'
      },
      system_admin: 'מנהל מערכת',
      bookkeeping: 'הנהלת חשבונות'
    },
    credit_card_details: {
      app_id: "App ID",
      private_key: "Private API Key",
      public_key: "Public API Key",
      business_number: "מס' בית עסק",
      institution_details: "פרטי מחיב אשראי",
      payment_on_create_order: "בצע תשלום בעת יצירת הזמנה",
      business_address: "כתובת בית העסק",
      business_phone: "טלפון בית העסק"
    },
    action: {
      add_filter: "הוסף סינון",
      add: "הוסף",
      back: "חזור",
      bulk_actions: "פריט אחד נבחר |||| %{smart_count} פריטים נבחרו",
      cancel: "ביטול",
      clear_input_value: "נקה ערך",
      clone: "שכפל",
      confirm: "אשר",
      create: "צור",
      import: "יבוּא",
      edit: "ערוך",
      delete: "מחק",
      list: "רשימה",
      refresh: "רענן",
      remove_filter: "הסר סינון",
      remove: "מחק",
      save: "שמור",
      search: "חפש",
      show: "הצג",
      sort: "מיין",
      undo: "בטל",
      export: "יצא נתונים",
      expand: "הרחב",
      close: "סגור",
      unselect: "unselect",
      export_admin_system: 'ייצוא למנהל מערכת',
      export_finance_system: 'ייצוא להנהלת חשבונות'
    },
    boolean: {
      true: "כן",
      false: "לא"
    },
    page: {
      create: "צור %{name}",
      dashboard: "לוח המחוונים",
      edit: "%{name} #%{id}",
      error: "שגיאה התרחשה",
      list: "רשימת %{name}",
      loading: "טוען",
      not_found: "לא קיים",
      show: "%{name} #%{id}",
      empty: "empty"
    },
    input: {
      file: {
        upload_several: "גרור מספר קבצים להעלאה או לחץ לבחור אחד.",
        upload_single: "גרור קובץ להעלאה או לחץ לבחור אחד."
      },
      image: {
        upload_several: "גרור מספר תמונות להעלאה או לחץ לבחור אחת.",
        upload_single: "גרור תמונה להעלאה או לחץ לבחור אחת."
      },
      references: {
        all_missing: "ישויות הקשר לא נמצאו.",
        many_missing: "לפחות אחת מישויות הקשר אינה זמינה יותר.",
        single_missing: "ישות הקשר אינה זמינה יותר."
      }
    },
    message: {
      about: "אודות",
      are_you_sure: "האם את/ה בטוח/ה ?",
      bulk_delete_content: "האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?",
      bulk_delete_title: "מחק %{name} |||| מחק %{smart_count} %{name} פריטים",
      delete_content: "האם אתה בטוח שברצונך למחוק את הפריט הזה?",
      delete_title: "מחק %{name} #%{id}",
      details: "פרטים",
      error: "קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.",
      invalid_form: "הטופס אינו תקין. אנא תקן את שגיאותיך",
      loading: "העמוד טוען, אנא המתן",
      no: "לא",
      yes: "כן",
      not_found: "הכנסת כתובת שגויה או לחצת על קישור לא תקין"
    },
    navigation: {
      no_results: "לא נמצאו תוצאות",
      no_more_results: "עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.",
      page_out_of_boundaries: "עמוד %{page} מחוץ לתחום",
      page_out_from_end: "לא ניתן להמשיך מעבר לעמוד האחרון",
      page_out_from_begin: "לא ניתן לגשת לפני העמוד הראשון",
      page_range_info: "%{offsetBegin}-%{offsetEnd} מתוך %{total}",
      page_rows_per_page: "מספר שורות לעמוד:",
      next: "הבא",
      prev: "הקודם",
      skip_nav: "skip_nav"
    },
    auth: {
      user_menu: "פרופיל",
      username: "שם משתמש",
      password: "סיסמא",
      sign_in: "כניסה",
      sign_in_error: "הכניסה נכשלה, אנא נסו שנית",
      logout: "התנתקות"
    },
    notification: {
      updated: "פריט עודכן בהצלחה",
      created: "פריט נוצר בהצלחה",
      deleted: "פריט נמחק בהצלחה",
      bad_item: "פריט לא תקין",
      item_doesnt_exist: "פריט לא קיים",
      http_error: "שגיאה בהתקשרות מול השרת",
      data_provider_error: "שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.",
      canceled: "הפעולה בוטלה",
      logged_out: "הנך מנותק, אנא התחבר מחדש."
    },
    validation: {
      required: "חובה",
      minLength: "חייב להכיל לפחות %{min} תווים",
      maxLength: "יכול להכיל לכל היותר %{max} תווים",
      minValue: "חייב להיות לפחות %{min}",
      maxValue: "חייב להיות %{max} או פחות",
      number: "חייב להיות מספר",
      email: 'כתובת דוא"ל לא תקנית',
      oneOf: "חייב להיות מאחת האפשרויות הבאות: %{options}",
      regex: "חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}"
    }
  },
  customRoot: {
    myMenu: {
      orders: "הזמנות",
      users: "משתמשים",
      uploadItems: "העלאת קבצי תפריט",
      organizations: "ארגונים",
      items: "פריטים",
      systems: "מערכות",
      order_settings: "הזמנת תצורה",
      customers: "לקוחות",
      settings_credit_card: "הגדרות מחיב כרטיס אשראי",
      settings_charge_page:"הגדרות דף סליקה",
      ordersUpload: "העלאת קובץ הזמנות",
      releaseNotes: "Release Note",
      noOptions: "אין תוצאות",
    },
    users: {
      list: {
        id: "מזהה",
        name: "שם",
        username: "שם משתמש",
        password: "סיסמא",
        role: "תפקיד",
        email: "דואר אלקטרוני",
        phone: "טלפון"
      },
      create: {
        first_name: "שם פרטי",
        last_name: "שם משפחה"
      }
    },
    customers: {
      firstname: "שם פרטי",
      lastname: "שם משפחה",
      email: "דואר אלקטרוני",
      phone: "טלפון",
      number: "מספר",
      budget_number: "מספר תקציב"
    },
    items: {
      name: 'שם',
      price: "מחיר",
      quantity: "כמות",
      category: "קטגוריה",
      image: "תמונה",
      barcode: "ברקוד",
      description: "תיאור",
      discount: "הנחה",
      weight: "מִשׁקָל",
      is_active: "זמין",
      not_active: "לא זמין",
      select: "בחר תמונה ממאגר התמונות שלך",
      clear: "למחוק תמונה",
      or: "אוֹ",
      sort_order: "סדר המיון",
      sort_order_helper: "מספר גבוה יותר מראה ראשון"
    },
    order_settings: {
      id: "זיהוי",
      name: "תווית",
      placeholder: "מציין מיקום",
      fieldType: "סוג קלט",
      isRequired: "נדרש",
      id_helper: "חייב להיות ייחודי ואנגלי",
      sortNumber: "מספר מיון"
    },
    orders: {
      list: {
        order_number: "מספר הזמנה",
        budget_number: "מספר תקציב",
        order_date: "תאריך ההזמנה",
        org_name: "שם הארגון",
        system_name: "שם המערכת",
        items: "הפריטים בהזמנה",
        customer_name: "שם הלקוח",
        customer_address: "כתובת הלקוח",
        customer_email: 'דוא"ל הלקוח',
        phone: "טלפון",
        order_type: "סוג ההזמנה",
        status: "מצב ההזמנה",
        search: "חיפוש",
        get_orders_button: "הבא הזמנות",
        details: "פרטים",
        createdAt: "נוצר ב"
      },

      items: {
        item_name: "שם לקוח",
        price: "מחיר",
        quantity: "כמות",
        category: "קטגוריה",
        remarks: "הערות",
        order_remarks: "הערות:",
        total: "סה\"כ",
        sort_order: "סדר המיון",
        sort_order_helper: "מספר גבוה יותר מראה ראשון"
      },
      modal: {
        print: "הדפס",
        update_status_button: "עדכן סטטוס להזמנה",
        send_sms_to_charge: "שלח הודעה לביצוע תשלום",
        confirm: "אישור",
        cancel: "ביטול"
      }
    },
    upload: {
      org: "בחר ארגון",
      sys: "בחר מערכת",
      upload_label: "בחר קובץ",
      loading: "הקובץ עולה, אנא המתן",
      successMessage: "הקובץ נטען בהצלחה",
      failureMessage: "טעינת הקובץ נכשלה",
      noFile: "לא נבחר קובץ",
      fileInvalid: "קובץ לא תקין",
      explainText: "הקפד על מבנה קובץ נכון",
      templateText: "הורד קובץ תבנית",
      mandatoryText: "שדות חובה כתובים אדום",
      noMandatoryText: "שרות רשות כתובים בשחור",
      explainStructure: "name|category|description|image|price|discount|weight|quantity|barcode|is_active",
      select_logo: "בחר לוגו",
      warningMessage: "בהעלאת קובץ חדש נתוני כל הפריטים במערכת ימחקו.\n" +
        "ניתן להוריד את הפריטים טרם העלאת הקובץ החדש על מנת לשמור אותם",
    },
    organizations: {
      list: {
        org_name: "שם הארגון",
        display_name: "שם תצוגה",
        created_at: "תאריך יצירה",
        systems: "מערכות",
        system: "מערכת",
        active: "פעיל",
        org_id: "מזהה ארגון",
        sys_id: "מזהה מערכת"
      }
    }
  },
  requestResult: {
    error: "ארעה שגיאה בתהליך הבקשה",
    success: "עבר בהצלחה"
  },
  title: "כותרת",
  content: "תוכן",
  romove_logo: "הסרת לוגו",
  select_logo: "בחר לוגו",
  change_logo: "החלף לוגו",
  message_sent_successfully: "הודעה נשלחה בהצלחה"
}
