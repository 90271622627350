import React from 'react'
import { SimpleForm, TextInput, BooleanInput, NumberInput, required, SelectInput, useNotify, useTranslate, Edit } from 'react-admin'
import { Navigate, useNavigate, useLocation  } from 'react-router-dom'

export const FieldType = {
	text: 'text',
	number: 'number',
	boolean: 'boolean',
	textArea: 'textArea',
	email: 'email',
	custom: 'custom'
}

const c = [
	{ id: 'text', name: 'text' },
	{ id: 'number', name: 'number' },
	{ id: 'boolean', name: 'boolean' },
	{ id: 'textArea', name: 'textArea' },
	{ id: 'email', name: 'email' },
	{ id: 'custom', name: 'custom' },
]

const OrderSettingsEdit = props => {
  const location = useLocation()
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/order_settings');
  }
	
	const { org_id, system_id } = location.state || {}
	
	if (!system_id) return <Navigate to='/order_settings' />
	
  return (
	<Edit {...props} mutationMode="pessimistic" title='resources.items.name' mutationOptions={{ onSuccess }} queryOptions={{ meta: {org_id, system_id} }}>
			<SimpleForm>
				{org_id && <TextInput source='org_id' label='resources.organizations.singular' validate={required()} defaultValue={org_id} disabled />}
				{system_id && <TextInput source='system_id' label='resources.systems.singular' validate={required()} defaultValue={system_id} disabled />}
				<TextInput source='id' label='customRoot.order_settings.id' validate={required()} helperText='customRoot.order_settings.id_helper' disabled={true} />
				<TextInput source='name' label='customRoot.order_settings.name' validate={required()} />
				<TextInput source='placeholder' label='customRoot.order_settings.placeholder' />
				<NumberInput source='sortNumber' label='customRoot.order_settings.sortNumber' validate={required()} />
				<SelectInput source='fieldType' label='customRoot.order_settings.fieldType' choices={c} validate={required()} />
				<BooleanInput source='isRequired' label='customRoot.order_settings.isRequired' defaultValue={false} />
			</SimpleForm>
		</Edit>
  )
}

export default OrderSettingsEdit
