import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const UserEdit = (props) => {
	return (
		<Edit {...props}>
			<SimpleForm>
				<TextInput source="id" disabled/>
				<TextInput source="name" />
				<TextInput source="username" />
				<TextInput source="email" />
				<TextInput source="role" />
				<TextInput source="phone" />
			</SimpleForm>
		</Edit>
	);
};
export default UserEdit;
