import React, { Component } from "react"
import { Admin, CustomRoutes } from "react-admin"
import { Provider as FetchProvider } from 'use-http'
import { createTheme } from "@mui/material/styles"
import blue from '@mui/material/colors/blue';
import polyglotI18nProvider from "ra-i18n-polyglot"
import { authProvider, layout } from "./core"
import restProvider, { addUploadFeature } from './core/restProvider'
import getRes from './core/Resources'
import { Dashboard } from "./components/main"
import { he, en } from "./i18n"
import settings from './tools/settings'
import rtlPlugin from 'stylis-plugin-rtl';
import {prefixer} from 'stylis';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import { Route } from "react-router-dom"


import { FileUpload } from "./components/upload"
import { OrdersUpload } from "./components/ordersUpload"
import CreditCardSettings from './components/settings/credit_card'
import ChargePageSettings from './components/settings/charge_page'

const uploadCapableDataProvider = addUploadFeature(restProvider)

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const messages = {
  en, he
}
const i18nProvider = polyglotI18nProvider(locale => (messages[locale] ? messages[locale] : messages.he), 'he')

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: blue,
  }
})

const token = authProvider.getToken()
const options = {
  headers : {
    Accept: 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  },
}


const lsSettings = localStorage.getItem("settings")
const { upload_orders_file } = JSON.parse(lsSettings) || {}

class App extends Component {
  render() {
    return (
      <CacheProvider value={cacheRtl}>
        <FetchProvider url={settings.apiUrl} options={options}>
          <Admin
            theme={theme}
            i18nProvider={i18nProvider}
            layout={layout}
            dashboard={Dashboard}
            dataProvider={uploadCapableDataProvider}
            authProvider={authProvider}
            disableTelemetry
          >
            {getRes}

            <CustomRoutes>
              <Route path='/upload' element={<FileUpload/>} />,
              <Route path='/settings_credit_card' element={<CreditCardSettings/>} />,
              <Route path='/settings_charge_page' element={<ChargePageSettings/>} />
              
              {upload_orders_file && (
                <Route path='/orderUpload' component={<OrdersUpload/>} />
              )}
            </CustomRoutes>
          </Admin>
        </FetchProvider>
      </CacheProvider>
    )
  }
}

export default App
