import authProvider from './authProvider'

const baseUrl = process.env.REACT_APP_API
const token = localStorage.getItem('token')
const options = {
    headers: new Headers({
        Accept: "application/json"
    })
}

export default {
    getOrganizations: async () => {
        options.headers.set('Authorization', `Bearer ${token}`)
        const response = await fetch(`${baseUrl}/organizations`, options)
        const { status } = response
        if (401 === status || 403 === status) {
            await authProvider.logout()
            window.location.replace('/login')
        }
        return await response.json()
    },
    getSystems: async (orgId) => {
        options.headers.set("Authorization", `Bearer ${token}`)
        const response = await fetch(`${baseUrl}/systems?filter=%7B%22org_id%22%3A${orgId}%7D`, options)
        return await response.json()
    },
    getSettings: async (orgId, systemId) => {
        options.headers.set("Authorization", `Bearer ${token}`)
        const response = await fetch(`${baseUrl}/settings?org_id=${orgId}&system_id=${systemId}`, options)
        return await response.json()
    },
    sendSmsToCharge: async (orderId, phone) => {
        const response = await fetch(`${baseUrl}/api/v1/send_sms_to_charge`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body: JSON.stringify({ order_id: orderId, phone: phone })
        })
        return await response.json()
    }
}