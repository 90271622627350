import React, { useRef } from "react"
import {
  AutocompleteInput,
  Datagrid,
  DeleteButton,
  ExportButton,
  Filter,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useListController,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useTranslate
} from "react-admin"

import { Add, Edit as EditIcon, Publish } from "@material-ui/icons"
import styled from "styled-components"
import xlsx from "xlsx"
import { default as MuiButton } from "@mui/material/Button"
import { Link } from "react-router-dom"

import ActionToolbar from "../common/ActionToolbar"
import useFetch from "use-http"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .column-price,
  .column-is_active,
  .column-undefined {
    width: 100px;
  }
`

function convertToCustomer({ _id, org_id, system_id, attributes }) {
  return {
    _id,
    ...attributes,
  }
}

const exporter = data => {
  const org_id = data[0].org_id
  const system_id = data[0].system_id
  
  const sdata = data.map(d => convertToCustomer(d))
  const wb = xlsx.utils.book_new()
  const s = xlsx.utils.json_to_sheet(sdata,
    { header: [
      '_id',
      'budget_number',
      'firstname',
      'lastname',
      'phone',
      'email'
    ]})
  
  xlsx.utils.book_append_sheet(wb, s, 'Sheet 1')
  xlsx.writeFile(wb, `${org_id}_${system_id}_customers.xlsx`)
}

const ItemsFilter = props => {
  const {permissions} = usePermissions()
  const translate = useTranslate()

  const { filterValues } = props.cprops
  const secondFilter = permissions === "admin" && filterValues
    ? { filter: { org_id: filterValues.org_id }}
    : {}

  return (
    <Filter {...props}>
      {permissions === "admin" &&
        <ReferenceInput 
           reference='organizations' source="org_id" alwaysOn perPage={1000} sort={{ field: 'display_name', order: 'ASC' }}>
          <AutocompleteInput 
            className="autocompeleteMekome"
            label='resources.organizations.singular' 
            optionText='display_name' 
            optionValue="id"
            noOptionsText={translate("customRoot.myMenu.noOptions")}
            matchSuggestion={(filter, choice) => {
              return choice.display_name.toLowerCase().includes(filter.toLowerCase())
            }}
          />
        </ReferenceInput>
      }
      <ReferenceInput label='customRoot.organizations.list.system' reference='systems' source="system_id" alwaysOn {...secondFilter} sort={{ field: 'system_name', order: 'ASC' }}>
        <AutocompleteInput 
            className="autocompeleteMekome"
            label='customRoot.organizations.list.system' 
            optionText='system_name'
            optionValue="id"
            noOptionsText={translate("customRoot.myMenu.noOptions")}
            matchSuggestion={(filter, choice) => {
              return choice.system_name.toLowerCase().includes(filter.toLowerCase())
            }}
          />
      </ReferenceInput>
      <TextInput source='q' label='customRoot.orders.items.item_name' alwaysOn resettable />
    </Filter>
  )
}

const CButton = styled(MuiButton)`
  color: #2196f3;
  padding: 2px;
`

const Input = styled.div`
  display: none;
`

const Actions = ({ ...props }) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  
  const refresh = useRefresh()
  const notify = useNotify()
  
  const input = useRef()
  
  const { post } = useFetch(`/customers/upload`, { cachePolicy: 'no-cache', })
  
  const {
    currentSort,
    resource,
    filterValues,
    total,
  } = useListContext()

  const { org_id, system_id } = filterValues
  const translate = useTranslate()
  const exportButtonPermission = props.permissions === "admin" ? !!(org_id && system_id) : !!system_id;

  const handleChange = e => {
    const form = new FormData()
    const file = e.target.files[0]
    form.append('file', file)
    form.append('org_id', org_id)
    form.append('system_id', system_id)
    post(form).then(r => {
      if (r.success) {
        notify('success', { type: 'success' })
        refresh()
      } else {
        const error = r.data;
        notify(error, { type: 'error' })
        refresh()
      }
    })
  }
  
  const handleClick = () => {
    input.current.click()
  }
  
  return (
    <TopToolbar>
      <CButton
        component={Link}
        disabled={!system_id}
        to={{
          pathname: '/customers/create',
        }}
        state={{
          org_id,
          system_id
        }}
      >
        <Add />
        {translate('ra.action.create')}
      </CButton>
      <CButton onClick={handleClick} disabled={!exportButtonPermission}>
        <Publish />
        {translate('ra.action.import')}
      </CButton>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <Input>
        <input type='file' ref={input} accept=".xlsx, .xls" onChange={handleChange} />
      </Input>
    </TopToolbar>
  )
}

const EditButton = props => {
  const record = useRecordContext();
  const { filterValues = {} } = useListContext()
  const { org_id, system_id } = filterValues
  return (
    <MuiButton
      {...props}
      size="small"
      component={Link}
      to={{
        pathname: `/customers/${record.id}`,
      }}
      state={{
        org_id: org_id,
        system_id: system_id,
        id: record.id
      }}
    > 
    <EditIcon/>
    ערוך
    </MuiButton>
  )
}


const Remove = props => {
  const { filterValues = {} } = useListContext()
  const { org_id, system_id } = filterValues
  return (
    <DeleteButton mutationMode="pessimistic" {...props} record={{ system_id, org_id, ...props.record }} />
  )
}

const CustomersList = ({ permissions, ...props }) => {
  const controllerProps = useListController(props)
  return (
    <Container>
      <List
        {...props}
        filters={<ItemsFilter permissions={permissions} cprops={controllerProps} />}
        exporter={exporter}
        perPage={25}
        actions={<Actions {...props} permissions={permissions} />}
        empty={false}
      >
        <Datagrid>
          <TextField source='attributes.budget_number' label='customRoot.customers.budget_number' />
          <TextField source='attributes.firstname' label='customRoot.customers.firstname' />
          <TextField source='attributes.lastname' label='customRoot.customers.lastname' />
          <TextField source='attributes.phone' label='customRoot.customers.phone' />
          <TextField source='attributes.email' label='customRoot.customers.email' />
          <ActionToolbar>
            <EditButton />
          </ActionToolbar>
        </Datagrid>
      </List>
    </Container>
  )
}

export default CustomersList
