import React, { Children, cloneElement } from 'react'
import styled from 'styled-components'

const StyledActionToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`

function ActionToolbar({ children, basePath, ...props }) {
  return (
    <StyledActionToolbar {...props}>
      {Children.map(children, button => cloneElement(button, { ...props, basePath }))}
    </StyledActionToolbar>
  )
}

export default ActionToolbar
