import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Edit, SimpleForm, TextInput, SelectInput, SelectArrayInput, FormDataConsumer, useEditController, useTranslate, useNotify } from "react-admin"
import settings from "../../tools/settings"
import { useNavigate  } from 'react-router-dom'

const apiUrl = settings.apiUrl
const token = localStorage.getItem("token")
const options = {
  headers: new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  })
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))

const UserEdit = props => {
  const classes = useStyles()
  const [orgChoices, setOrgChoice] = useState([])
  const [sysChoices, setSysChoices] = useState([])

  const { record } = useEditController(props)

  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/users');
  }
  
  const fetchOrgChoices = async () => {
    let response = await fetch(`${apiUrl}/organizations`, options)
    response = await response.json()
    response = response.data.map(org => ({
      id: org.id,
      name: org.display_name
    }))
    setOrgChoice(response)
  }

  const fetchSystemChoices = async event => {
    try {
      let value
      if (!Array.isArray(event) && typeof event === "object") value = event.target.value
      else value = event
      const response = await fetch(`${apiUrl}/systems?filter=%7B%22org_id%22%3A${value}%7D`, options)
      const data = await response.json()
      const syss = data.data || []
      const systems = syss.map(sys => ({
        id: sys.id.toString(),
        name: sys.system_name
      }))
      setSysChoices(systems)
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchOrgChoices()
    record && fetchSystemChoices(record.org_id)
  }, [record?.org_id])

  return (
    <Edit {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm>
      <FormDataConsumer>
          {({ formData }) => {
            return (
              <div className={classes.formContainer}>
        <TextInput source='id' label='customRoot.users.list.id' />
        <TextInput source='name' label='customRoot.users.list.name' />
        <TextInput source='username' label='customRoot.users.list.username' />
        <TextInput source='email' label='customRoot.users.list.email' />
        <TextInput source='phone' label='customRoot.users.list.phone' />
        <SelectInput
          label='customRoot.users.list.role'
          source='role'
          choices={[
            { id: "admin", name: "Admin" },
            { id: "user", name: "User" }
          ]}
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off"
            }
          }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === "user" && (
              <SelectInput
                label='customRoot.upload.org'
                source='org_id'
                initialValue={record.org_id}
                choices={orgChoices}
                onChange={value => fetchSystemChoices(value)}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === "user" &&
            sysChoices && <SelectArrayInput initialValue={record.system_id} label='customRoot.upload.sys' source='system_id' choices={sysChoices} />
          }
        </FormDataConsumer>
        </div>
          )}}
          </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
export default UserEdit
