import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import styled from "styled-components"
import { TextField } from "@mui/material"
import ItemList from "./itemList"
import { useTranslate } from "react-admin"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    direction: rtl;
    align-items: center;
  }
`

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {
    margin: '0 auto'
  },
  marginBottom: {
      marginBottom: '20px'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const OrderModal = ({ open, setOpen, record }) => {
    const translate = useTranslate()
  const { order_number } = record

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <StyledDialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Button autoFocus variant="contained" onClick={handlePrint} className={classes.button}>
            {translate('customRoot.orders.modal.print')}
          </Button>
        </Toolbar>
      </AppBar>
      <FormContainer>
        <TextField fullWidth disabled variant="standard" defaultValue={order_number} label={translate('customRoot.orders.list.order_number')} />
        <TextField fullWidth disabled variant="standard" defaultValue={record?.fields?.budget_number || ''} label={translate('customRoot.orders.list.budget_number')} />
        <TextField fullWidth disabled variant="standard" defaultValue={record?.fields?.name || ''} label={translate('customRoot.orders.list.customer_name')}  />
        <TextField fullWidth disabled variant="standard" defaultValue={record?.fields?.customer_address || ''} label={translate('customRoot.orders.list.customer_address')} />
        <TextField fullWidth disabled variant="standard" defaultValue={record?.fields?.customer_email || ''} label={translate('customRoot.orders.list.customer_email')} />
        <TextField fullWidth disabled variant="standard" defaultValue={record?.fields?.phone || ''} label={translate('customRoot.orders.list.phone')} className={classes.marginBottom} />
        <ItemList record={record} />
      </FormContainer>
    </StyledDialog>
  )
}

export default OrderModal
