// in src/Dashboard.js
import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { Title } from "react-admin"
import styled from "styled-components"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: left;
`

const Dashboard = () => (
  <Container>
    <Card>
      <Title title='Welcome to the Mekome Takeaway backoffice' />
      <CardContent>Mekome Takeaway backoffice</CardContent>
    </Card>
  </Container>
)

export default Dashboard
