import React from "react"
import { List, Datagrid, TextField, ArrayField, DateField, EmailField, BooleanField } from "react-admin"
import ItemList from "./itemList"
import PostFilter from "./postFilter"

const PostPanel = (props) => (
  <ItemList {...props} label='customRoot.orders.list.items' sortable={false} />
);

const OrgList = props => {
  return (
    <List {...props} filters={<PostFilter />}>
      <Datagrid rowClick='edit' expand={<PostPanel {...props} />}>
        <TextField source='org_name' label='customRoot.organizations.list.org_name' sortable={false} />
        <TextField source='display_name' label='customRoot.organizations.list.display_name' sortable={false} />
        <TextField source='id' label='customRoot.organizations.list.org_id' sortable={false} />
        <BooleanField source="active" label='customRoot.organizations.list.active' sortable={false} />
        <DateField source='createdAt' label='customRoot.organizations.list.created_at' locales="he-IL" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default OrgList
