// in src/Menu.js
import React from "react"
import { useMediaQuery } from "@mui/material"
import { MenuItemLink, useResourceDefinitions, useTranslate, useSidebarState } from "react-admin"
import DefaultIcon from "@material-ui/icons/ViewList"
import PublishIcon from '@material-ui/icons/Publish'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SettingsIcon from '@material-ui/icons/Settings'
import { ReleaseNotesDialog } from "./ReleaseNotesDialog"

const stop = ['files', 'systems']
const permissions = localStorage.getItem('permissions')
const settings = localStorage.getItem("settings")
const { upload_orders_file } = JSON.parse(settings) || {}

const Menu = ({ onMenuClick, logout }) => {
  const translate = useTranslate()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"))
  const [open] = useSidebarState();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);

  return (
    <div>
      {resources.filter(r => !stop.includes(r.name)).map(resource => {
        if (upload_orders_file && (resource.name === 'customers' || resource.name === 'items' || resource.name === 'order_settings')) {
          return null
        }
        return <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`customRoot.myMenu.${resource.name}`)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      })}
      {permissions === 'admin' && <MenuItemLink to='/settings_credit_card' primaryText={translate(`customRoot.myMenu.settings_credit_card`)} leftIcon={<SettingsIcon />} onClick={onMenuClick} />}
      <MenuItemLink to='/settings_charge_page' primaryText={translate(`customRoot.myMenu.settings_charge_page`)} leftIcon={<SettingsIcon />} onClick={onMenuClick} />
      {!upload_orders_file && <MenuItemLink to='/upload' primaryText={translate(`customRoot.myMenu.uploadItems`)} leftIcon={<PublishIcon />} onClick={onMenuClick} />}
      {upload_orders_file && <MenuItemLink to='/orderUpload' primaryText={translate(`customRoot.myMenu.ordersUpload`)} leftIcon={<CloudUploadIcon />} onClick={onMenuClick} />}
      {isXSmall && logout}
      <ReleaseNotesDialog/>
    </div>
  )
}

export default Menu
