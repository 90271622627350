import React, { useState } from 'react'
import styled from 'styled-components'
import { Edit, SimpleForm, TextInput, ImageInput, ImageField, Button, BooleanInput, useInput, NumberInput, required, useNotify, useTranslate } from 'react-admin'
import CreateOrEdit from '../common/CreateOrEdit'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

import ImagePicker from './imagePicker'

const ImageWrap = styled.div`
  width: 200px;
  height: 200px;
  padding: 1rem;
  flex: 0 0 200px;
`

const SImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Image = (props) => {
  const { field: { onChange, value } } = useInput(props)

  const onClick = () => onChange('')
  
  if (!value || value === '') return null
  
  return (
    <div style={{ marginTop: '1rem' }}>
			<ImageWrap><SImage src={value} alt='' /></ImageWrap>
			<div><Button label='customRoot.items.clear' onClick={onClick} style={{ color: '#cd3636' }} /></div>
		</div>
  )
}

const SImageInput = styled(ImageInput)`
  width: 256px;
  border: 1px dashed #ddd;
`

const Or = styled.div`
	width: 256px;
	padding: 1rem 110px;
`

const ItemCreateOrEdit = (props) => {
  const location = useLocation();
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/items');
  }
  
  const onClick = () => setOpen(true)

  const isCreate = /create/.test(location.pathname)
  const { org_id, system_id } = location.state || {}

  if (isCreate && (!system_id)) return <Navigate to='/items' />
	
  return (
    <CreateOrEdit {...props} title='resources.items.name' mutationOptions={{ onSuccess }}>
			<SimpleForm>
				{isCreate && org_id && <TextInput source='org_id' label='resources.organizations.singular' validate={required()} defaultValue={org_id} disabled />}
				{isCreate && system_id && <TextInput source='system_id' label='resources.systems.singular' validate={required()} defaultValue={system_id} disabled />}
				<BooleanInput source='is_active' label='customRoot.items.is_active' defaultValue={true} />
				<TextInput source='name' label='customRoot.items.name' validate={required()} />
				<TextInput source='category' label='customRoot.items.category' validate={required()} />
				<TextInput source='description' label='customRoot.items.description' />
				<NumberInput source='sort_order' label='customRoot.items.sort_order' helperText="customRoot.items.sort_order_helper" />
				<NumberInput source='weight' label='customRoot.items.weight' />
				<NumberInput source='quantity' label='customRoot.items.quantity' />
				<NumberInput source='price' label='customRoot.items.price' validate={required()} />
				<NumberInput source='discount' label='customRoot.items.discount' />
				<SImageInput source='file' accept='image/*' label='customRoot.items.image'>
					<ImageField source='src' />
				</SImageInput>
				<Or>אוֹ</Or>
				<Button label='customRoot.items.select' variant='contained' onClick={onClick} />
				<Image source='image' />
				<ImagePicker ra_props={props} source='image' open={open} onClose={() => setOpen(false)} />
			</SimpleForm>
		</CreateOrEdit>
  )
}

export default ItemCreateOrEdit
