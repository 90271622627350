import React, { Fragment, useEffect, useState } from "react"
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListController,
  usePermissions,
  useTranslate
} from "react-admin"
import styled from "styled-components"
import { format, subWeeks } from "date-fns"
import ItemList from "./itemList"
import OrderModal from "./orderModal"
import UpdateStatusButton from "./updateStatus"
import FieldsField from "../common/FieldsField"
import ExportToSystemAdmin from "./exportSystemAdmin"
import { getExporter } from "../../tools/utils"

const Container = styled.div`
  .column-order_number {
    width: 100px;
  }
`

const PostPanel = props => <ItemList {...props} label='customRoot.orders.list.items' sortable={false} />

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportToSystemAdmin filterValues={filterValues} currentSort={currentSort} />
    </TopToolbar>
  )
}

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null
}

const PostBulkActionButtons = props => (
  <Fragment>
    <UpdateStatusButton {...props} />
  </Fragment>
)

const datesDefault = {
  to: format(new Date(), 'yyyy-MM-dd'),
  from: format(subWeeks(new Date(), 1), 'yyyy-MM-dd')
}

const OrdersFilter = props => {
  const translate = useTranslate()
  const { filterValues } = props.cprops
  const isAdmin = props.permissions === 'admin'

  const secondFilter = isAdmin && filterValues
    ? { filter: { org_id: filterValues.org_id } }
    : {}
  const { org_id, system_id } = filterValues
  const [ids, setIds] = useState({ org_id, system_id })

  useEffect(() => {
    setIds({ org_id, system_id })
  }, [org_id, system_id])

  const isDisabled = () => {
    if(isAdmin) {
      return !ids.org_id || !ids.system_id
    } else {
      return !ids.system_id
    }
  }
  
  return (
    <Filter {...props}>
      {isAdmin &&
        <ReferenceInput label='resources.organizations.singular' reference='organizations' source='org_id' alwaysOn perPage={1000} sort={{ field: 'display_name', order: 'ASC' }}>
          <AutocompleteInput 
            className="autocompeleteMekome"
            label='resources.organizations.singular' 
            optionText='display_name' 
            optionValue="id"
            noOptionsText={translate("customRoot.myMenu.noOptions")}
            matchSuggestion={(filter, choice) => {
              return choice.display_name.toLowerCase().includes(filter.toLowerCase())
            }}
          />
        </ReferenceInput>
      }
      <ReferenceInput label='customRoot.organizations.list.system' reference='systems' source='system_id' alwaysOn {...secondFilter} disabled={isAdmin && !ids.org_id} sort={{ field: 'system_name', order: 'ASC' }}>
      <AutocompleteInput 
            className="autocompeleteMekome"
            label='customRoot.organizations.list.system' 
            optionText='system_name' 
            optionValue="id"
            noOptionsText={translate("customRoot.myMenu.noOptions")}
            matchSuggestion={(filter, choice) => {
              return choice.system_name.toLowerCase().includes(filter.toLowerCase())
            }}
          />
      </ReferenceInput>
      <DateInput disabled={isDisabled()} source='from' alwaysOn />
      <DateInput disabled={isDisabled()} source='to' alwaysOn />
    </Filter>
  )
}

const StickyDataGrid = styled(Datagrid)`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 83vh;
  
  thead {
    flex: 0 0 auto;
  }
  tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  .MuiTableCell-paddingCheckbox {
    padding: 0;
    width: 4%;
  }
`

const ffformat = {
  hour12: false,
  hour: "2-digit",
  minute: '2-digit',
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}

const OrderList = (props) => {
  const [open, setOpen] = useState(false)
  const {permissions} = usePermissions()
  const [modalData, setModalData] = useState(false)
  const controllerProps = useListController(props)

  const postRowClick = (id, basePath, record) => {
    setModalData(record)
    setOpen(true)
  }

  return (
    <Container>
      <List
        {...props}
        filters={<OrdersFilter permissions={permissions} cprops={controllerProps} />}
        perPage={25}
        bulkActionButtons={<PostBulkActionButtons orders={controllerProps.data} />}
        actions={<ListActions maxResults={100000} currentSort={controllerProps.sort} filterValues={controllerProps.filterValues}/>}
        filterDefaultValues={datesDefault}
        exporter={getExporter()}
      >
        <StickyDataGrid expand={<PostPanel {...props} />} rowClick={postRowClick}  >
          <TextField source='order_number' label='customRoot.orders.list.order_number' />
          <DateField source='createdAt' label='customRoot.orders.list.createdAt' showTime options={ffformat} />
          <FieldsField source='fields' label='customRoot.orders.list.details' />
          <TextField source='status' label='customRoot.orders.list.status' />
        </StickyDataGrid>
      </List>
      <OrderModal open={open} setOpen={setOpen} record={modalData} />
    </Container>
  )
}

export default OrderList
