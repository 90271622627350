import React, { useEffect } from "react"
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  Filter,
  TextInput,
  useListController,
  BooleanField,
  BulkDeleteButton,
  useRecordContext,
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext, useTranslate,
  AutocompleteInput,
  usePermissions,
} from 'react-admin'
import { VisibilityOff, Visibility, Add, Edit as EditIcon } from '@material-ui/icons'
import styled from "styled-components"
import xlsx from 'xlsx'
import { default as MuiButton } from '@mui/material/Button'
import { Link } from 'react-router-dom'

import ActionToolbar from '../common/ActionToolbar'

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .column-price,
  .column-is_active,
  .column-undefined {
    width: 100px;
  }
`

const c =   {
  org_id: { type: Number, required: true, unique: false },
  system_id: { type: Number, required: true, unique: false },
  name: { type: String, required: true, unique: false },
  description: { type: String, required: false, unique: false },
  price: { type: Number, required: true, unique: false },
  image: { type: String, required: false, unique: false },
  category: { type: String, required: true, unique: false },
  weight: { type: Number, required: false, unique: false },
  quantity: { type: Number, required: false, unique: false },
  discount: { type: Number, required: false, unique: false },
  barcode: { type: String, required: false, unique: false },
  is_active: { type: Boolean, required: false, default: true }
}

function convertToItem({
                         name,
                         category,
                         description = '',
                         image = '',
                         sort_order= 0,
                         is_active,
                         weight = 0,
                         quantity = 0,
                         price,
                         discount = 0,
                         barcode = ''
                       }) {
  return {
    name,
    category,
    description,
    sort_order,
    image,
    price,
    discount,
    weight,
    quantity,
    barcode,
    is_active
  }
}

const exporter = data => {
  const org_id = data[0].org_id
  const system_id = data[0].system_id
  
  const sdata = data.map(d => convertToItem(d))
  const wb = xlsx.utils.book_new()
  const s = xlsx.utils.json_to_sheet(sdata,
    { header: [
        'name',
        'category',
        'description',
        'sort_order',
        'image',
        'price',
        'discount',
        'weight',
        'quantity',
        'barcode',
        'is_active'
    ]})
  
  xlsx.utils.book_append_sheet(wb, s, 'Sheet 1')
  xlsx.writeFile(wb, `${org_id}_${system_id}.xlsx`)
}

const ItemsFilter = props => {
  const {permissions} = usePermissions()
  const translate = useTranslate()
  const { filterValues } = props.cprops
  const secondFilter = permissions === "admin" && filterValues
    ? { filter: { org_id: filterValues.org_id }}
    : {}
  return (
    <Filter {...props}>
      {permissions === "admin" &&
        <ReferenceInput label='resources.organizations.singular' reference='organizations' source="org_id" alwaysOn perPage={1000} sort={{ field: 'display_name', order: 'ASC' }}>
           <AutocompleteInput
            className="autocompeleteMekome"
            label='resources.organizations.singular' 
            optionText='display_name' 
            optionValue="id"
            matchSuggestion={(filter, choice) => {
             return choice.display_name.toLowerCase().includes(filter.toLowerCase())
            }}
          />
        </ReferenceInput>
      }
      <ReferenceInput label='customRoot.organizations.list.system' reference='systems' source="system_id" alwaysOn {...secondFilter} sort={{ field: 'system_name', order: 'ASC' }}>
        <AutocompleteInput 
          className="autocompeleteMekome"
          label='customRoot.organizations.list.system' 
          optionText='system_name' 
          optionValue="id"
          noOptionsText={translate("customRoot.myMenu.noOptions")}
          matchSuggestion={(filter, choice) => {
            return choice.system_name.toLowerCase().includes(filter.toLowerCase())
          }}
        />
      </ReferenceInput>
      <TextInput source='q' label='customRoot.orders.items.item_name' alwaysOn resettable />
    </Filter>
  )
}

const BulkDisableActive = ({ is_active, selectedIds, ...rest }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'items',
    { ids: selectedIds, data: { is_active } },
    {
      onSuccess: () => {
        refresh();
        notify(is_active ? 'פריטים זמין' : 'פריטים לא זמין');
        unselectAll('items');
      },
      onFailure: error => notify('Error: posts not updated', { type: 'warning' }),
    }
  );
  return (
    <Button
      label={`customRoot.items.${is_active ? 'is_active' : 'not_active'}`}
      disabled={loading}
      onClick={updateMany}
    >
      {is_active ? <Visibility /> : <VisibilityOff />}
    </Button>
  )
}

const Bulk = props => {
  const { system_id, org_id } = props.filterValues
  return (
    <>
      <BulkDisableActive is_active={true} {...props} />
      <BulkDisableActive is_active={false} {...props} />
      <BulkDeleteButton {...props} onClick={() => localStorage.setItem(props.resource, JSON.stringify({system_id, org_id}))}  />
    </>
  )
}

const PriceField = ({ source }) => <span>{useRecordContext()[source]} ₪</span>

const CButton = styled(MuiButton)`
  color: #2196f3;
  padding: 2px;
`

const Actions = ({ ...props }) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    hasCreate,
    filterValues,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()
  const { org_id, system_id } = filterValues
  const translate = useTranslate()
  
  return (
    <TopToolbar>
      <CButton
        component={Link}
        disabled={!system_id}
        to={{
          pathname: '/items/create',
        }}
        state={{
          org_id,
          system_id
        }}

      >
        <Add />
        {translate('ra.action.create')}
      </CButton>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  )
}

const EditButton = props => {
  const record = useRecordContext();
  const { filterValues = {} } = useListContext()
  const { org_id, system_id } = filterValues
  return (
    <MuiButton
      {...props}
      size="small"
      component={Link}
      to={{
        pathname: `/items/${record.id}`,
      }}
      state={{
        org_id: org_id,
        system_id: system_id,
        id: record.id
      }}
    > 
    <EditIcon/>
    ערוך
    </MuiButton>
  )
}


const ItemList = ({ permissions, ...props }) => {
  const controllerProps = useListController(props)
  return (
    <Container>
      <List
        {...props}
        filters={<ItemsFilter permissions={permissions} cprops={controllerProps} />}
        bulkActionButtons={<Bulk {...props} />}
        exporter={exporter}
        perPage={25}
        actions={<Actions {...props} permissions={permissions} />}
        empty={false}
      >
        <Datagrid>
          <BooleanField source='is_active' label='customRoot.items.is_active' />
          <TextField source='name' label='customRoot.orders.items.item_name' />
          <TextField source='category' label='customRoot.orders.items.category' />
          <PriceField source='price' label='customRoot.orders.items.price' sortable={false} />
          <TextField source='sort_order' label='customRoot.orders.items.sort_order' />
          <ActionToolbar>
            <EditButton />
          </ActionToolbar>
        </Datagrid>
      </List>
    </Container>
  )
}

export default ItemList
