import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Paper
} from "@mui/material"
import { Clear } from "@material-ui/icons"

import { useInput, useNotify, useRecordContext, useRefresh, useTranslate, useUpdateMany } from "react-admin"

import useFetch from "use-http"

import Spinner from "../common/Spinner"
import { useLocation } from "react-router-dom"

const SPaper = styled(Paper)`
  padding: 1rem;
  padding-bottom: 3rem;
  width: 250px;
  height: calc(250px + 1.5rem);
  margin: 0.5rem;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageWrap = styled.div`
  width: 250px;
  height: 250px;
  flex: 0 0 250px;
  cursor: pointer;
`

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InputWrap = styled(OutlinedInput)`
  width: 250px;
`

const Image = ({ name, Key, ra_props, getImages, ...props }) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify()
  const translate = useTranslate()

  const ref = useRef()
  const [ n, setN ] = useState(name)
  const [ focus, setFocus ] = useState(false)
  const [ nnn, setNnn ] = useState()
  const { del } = useFetch(`/files?Key=${encodeURIComponent(Key)}`)
  const [updateMany] = useUpdateMany(
    "items",
    { ids: record ? [record.id] : [], data: { image: '' } },
    {
      onSuccess: () => {
        refresh()
        notify(translate("customRoot.upload.successMessage"), { type: "success" })
      },
      onFailure: error => notify(translate("customRoot.upload.failureMessage"), { type: "warning" })
    }
  )

  const onBlur = () => {
    setTimeout(() => { setFocus(false); setN(name) }, 100)
  }
  
  const onChange = e => {
    setN(e.target.value)
    setNnn(e.target.value)
  }

  const updateItemImage = async () => {
    if (record && props.src === record.image) {
      await updateMany();
    }
  }

  const onDelete = async () => {
    await del();
    await updateItemImage();
    if (getImages) {
      getImages();
    }
  }

  return (
    <SPaper>
      <ImageWrap>
        <InputWrap
          onBlur={onBlur}
          onFocus={() => setFocus(true)}
          inputRef={ref}
          value={n}
          onChange={onChange}
          endAdornment={(
            <IconButton><Clear onClick={onDelete} /></IconButton>
          )} />
        <Img {...props} />
      </ImageWrap>
    </SPaper>
  )
}

const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80vw;
  min-height: 30vh;
`

const SDialog = styled(Dialog)`
  transition: all 300ms ease-in-out;
`

const ImagePicker = ({ open, onClose, ra_props, ...rest }) => {
  const location = useLocation();
  const { field: { onChange } } = useInput(rest)
  const [ images, setImages ] = useState([])
  const { org_id, system_id } = location.state || {}
  const { get, loading } = useFetch(`/files?org_id=${org_id}&system_id=${system_id}`, { cachePolicy: 'no-cache' })

  const getImages = async () => {
    const res = await get()
    if (res.success) setImages(res.data)
  }
  
  useEffect(() => {
    if (open) getImages()
  }, [open])
  
  const onClick = src => {
    onChange(src)
    onClose()
  }
  
  return (
    <SDialog open={open} onClose={onClose} maxWidth='xl'>
      <DialogTitle>אוסף התמונות שלי</DialogTitle>
      <DialogContent>
        <Gallery>
          {loading && <Loading><Spinner /></Loading>}
          {images.map(i => <Image key={i.name} onClick={() => onClick(i.src)} {...i} ra_props={ra_props} getImages={getImages} />)}
        </Gallery>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>סגור</Button>
      </DialogActions>
    </SDialog>
  )
}

export default ImagePicker
