import React from "react"
import { Edit, SimpleForm, TextInput, SimpleFormIterator, ArrayInput, BooleanInput, useNotify, useTranslate } from "react-admin"
import { useNavigate  } from 'react-router-dom'

const OrgEdit = props => {
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/organizations');
  }

  return (
    <Edit {...props} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source='id' label='customRoot.users.list.id' />
        <TextInput source='createdAt' label='customRoot.organizations.list.created_at' locales='he-IL' />
        <TextInput source='org_name' label='customRoot.organizations.list.org_name' />
        <TextInput source='display_name' label='customRoot.organizations.list.display_name' />
        <BooleanInput label="customRoot.organizations.list.active" source="active" />
        <ArrayInput source='systems'>
          <SimpleFormIterator disableClear={true}>
            <TextInput source='system_id' label='customRoot.users.list.id' />
            <TextInput source='system_name' label='customRoot.organizations.list.display_name' />
            <TextInput source='email' label='customRoot.users.list.email' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
export default OrgEdit
