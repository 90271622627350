import React, { useEffect, useState } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  PasswordInput,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  useNotify, 
} from "react-admin"
import { Snackbar, Alert } from "@mui/material"
import { makeStyles } from "@mui/styles"
import settings from "../../tools/settings"
import styled from "styled-components"
import { Navigate, useNavigate  } from 'react-router-dom'

const apiUrl = settings.apiUrl
const token = localStorage.getItem("token")
const options = {
  headers: new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  })
}

const StyledMuiAlert = styled(Alert)`
  flex-direction: row-reverse;
`

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))

const validateFirstName = [required(), minLength(2), maxLength(15)]
const validatePassword = [required(), minLength(2)]
const validateEmail = [required(), email()]

const UserCreate = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const [orgChoices, setOrgChoice] = useState([])
  const [sysChoices, setSysChoices] = useState([])
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/users');
  }

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState({})

  const fetchOrgChoices = async () => {
    try {
      let response = await fetch(`${apiUrl}/organizations`, options)
      response = await response.json()
      response = response.data.map(org => ({
        id: org.id,
        name: org.display_name
      }))
      setOrgChoice(response)
    } catch (error) {
      setMessage({ message: translate("customRoot.upload.failureMessage"), severity: "success" })
      setOpen(true)
    }
  }

  const fetchSystemChoices = async event => {
    try {
      const { value } = event.target
      const response = await fetch(`${apiUrl}/systems?filter=%7B%22org_id%22%3A${value}%7D`, options)
      const j = await response.json()
      const systems = j.data.map(sys => ({
        id: sys.id,
        name: sys.system_name
      }))
      setSysChoices(systems)
    } catch (err) {
      setMessage({ message: translate("customRoot.upload.failureMessage"), severity: "success" })
      setOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    fetchOrgChoices()
  }, [])

  const Alert = props => {
    return <StyledMuiAlert elevation={6} variant='filled' {...props} />
  }
  return (
    <div>
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <div className={classes.formContainer}>
          <TextInput
            label='customRoot.users.list.username'
            source='username'
            validate={validateFirstName}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <PasswordInput
            label='customRoot.users.list.password'
            source='password'
            type='password'
            validate={validatePassword}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <TextInput
            label='customRoot.users.create.first_name'
            source='first_name'
            validate={validateFirstName}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <TextInput
            label='customRoot.users.create.last_name'
            source='last_name'
            validate={validateFirstName}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <TextInput
            label='customRoot.users.list.email'
            source='email'
            validate={validateEmail}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <TextInput
            label='customRoot.users.list.phone'
            source='phone_number'
            validate={required()}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <SelectInput
            label='customRoot.users.list.role'
            source='role'
            choices={[
              { id: "admin", name: "Admin" },
              { id: "user", name: "User" }
            ]}
            validate={required()}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role === "user" && <SelectInput validate={required()} label='customRoot.upload.org' source='org_id' choices={orgChoices} onChange={value => fetchSystemChoices(value)} />
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => (formData.org_id && sysChoices) && <SelectArrayInput validate={required()} label='customRoot.upload.sys' source='system_id' choices={sysChoices} />}
          </FormDataConsumer>
          </div>
          )}}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.severity}>
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  )
}
export default UserCreate