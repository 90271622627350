import React, { useState, useEffect } from 'react'
import { useDataProvider, useTranslate, SelectInput, SimpleForm, required, TextInput, BooleanInput, useNotify } from 'react-admin'
import { InputLabel, Snackbar, Grid, Card, Typography, MenuItem, Select, Autocomplete, TextField } from '@mui/material'
import styled from 'styled-components'
import requests from '../../core/requests'
import Alert from '../common/Alert'

const StyledSimpleForm = styled(SimpleForm)`
    & button {
        width: 50%;
        margin-right: 25%;
    };
    flex: 2;
    background-color: #f5f5f5
`
const StyledFormContent = styled.div`
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
`

const StyledTypography = styled(Typography)`
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin: 12%;
`

const StyledContainer = styled.div`
    margin: 5%;
    display: flex;
`

const StyledContainerInput = styled.div`
    width: 50%
`

const StyledInput = styled.div`
    margin: 4rem;
`

const CreditCardSettings = () => {
    const notify = useNotify()
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const validateRequired = [required()]
    const [orgSelected, setOrgSelected] = useState('')
    const [systemSelected, setSystemSelected] = useState('')
    const [organizations, setOrganizations] = useState([])
    const [systems, setSystems] = useState([])
    const [isOpenSnackbar, setOpenSnackbar] = useState(false)
    const [snackbar, setSnackbar] = useState({})
    const [settings, setSettings] = useState({})

    const openSnackbar = (message, severity) => {
        setOpenSnackbar(true)
        setSnackbar({ message: message, severity: severity })
    }

    const getOrganizations = async () => {
        try {
            const res = await requests.getOrganizations()
            const organizations = res.data.map(org => ({
                id: org.id,
                name: org.display_name
            })).sort((a, b) => a.name.localeCompare(b.name))
            setOrganizations(organizations)
        } catch (error) {
            openSnackbar(error.message, 'error')
        }
    }

    const orgSelectedChanged = async value => {
        try {
            const orgId = value
            setOrgSelected(orgId)
            setSystemSelected(null)
            setSettings({})
            const res = await requests.getSystems(orgId)
            setSystems(res.data.sort((a, b) => a.system_name.localeCompare(b.system_name)))
        } catch (error) {
            openSnackbar(error.message, 'error')
        }
    }

    const getSettings = async value => {
        try {
            const systemId = value
            const res = await requests.getSettings(orgSelected, systemId)
            setSettings(res.data)
        } catch (error) {
            console.error(error)
            notify(translate('requestResult.error'), { type: "error" })
        }
    }

    const handleSave = async (values) => {
        try {
            const data = {
                business_number: values.business_number,
                public_key: values.public_key,
                app_id: values.app_id,
                private_key: values.private_key,
                payment_on_create_order: values.payment_on_create_order
                
            }
            const res = await dataProvider.CREATE(
                `update_credit_card_insitution/${orgSelected}/${systemSelected}`,
                { data: { data } }
            )
            
            if (res.data) {
                notify(translate("requestResult.success"), { type: "success" })
            } else {
                notify(translate('requestResult.error'), { type: "error" })
            }
        } catch (error) {
            console.log(error)
            notify(error.message, { type: 'error'})
        }

    }

    useEffect(() => {
        getOrganizations()
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }
    return (
        <StyledContainer>
            <StyledContainerInput>
            <StyledInput style={{marginTop: '0px'}}>
                    <Autocomplete
                      labelId='orgs-select-label'
                      options={organizations}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label={translate('customRoot.upload.org')} />}
                      onChange={(event, value) => { 
                        orgSelectedChanged(value.id)
                    }}
                    />

                </StyledInput>
                <StyledInput>
                    <Autocomplete
                      labelId='orgs-select-label'
                      options={systems}
                      getOptionLabel={(option) => option.system_name}
                      renderInput={(params) => <TextField {...params} source="system_name" label={translate('customRoot.upload.sys')} />}
                      onChange={(event, value) => {
                        setSystemSelected(value.system_id)
                        getSettings(value.system_id)
                      }}
                    />
                </StyledInput>
            </StyledContainerInput>
            <StyledSimpleForm onSubmit={handleSave} record={
                {
                    business_number: settings.credit_card_institution?.business_number,
                    app_id: settings.credit_card_institution?.app_id,
                    public_key: settings.credit_card_institution?.public_key,
                    private_key: settings.credit_card_institution?.private_key,
                    payment_on_create_order: settings.credit_card_institution?.payment_on_create_order
                }}>
                <StyledFormContent>
                    <StyledTypography>
                        {translate('ra.credit_card_details.institution_details')}
                    </StyledTypography>
                    <Grid container spacing={1} style={{ justifyContent: 'center' }}>
                        <TextInput disabled={!systemSelected} fullWidth source='business_number' validate={validateRequired} label='ra.credit_card_details.business_number' />
                        <TextInput disabled={!systemSelected} fullWidth source='app_id' validate={validateRequired} label='ra.credit_card_details.app_id' />
                        <TextInput disabled={!systemSelected} fullWidth source='public_key' validate={validateRequired} label='ra.credit_card_details.public_key' />
                        <TextInput disabled={!systemSelected} fullWidth source='private_key' validate={validateRequired} label='ra.credit_card_details.private_key' />
                        <BooleanInput disabled={!systemSelected} fullWidth source='payment_on_create_order' label='ra.credit_card_details.payment_on_create_order' />
                    </Grid>
                </StyledFormContent>
            </StyledSimpleForm>
            <Snackbar open={isOpenSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </StyledContainer>
    )

}

export default CreditCardSettings
