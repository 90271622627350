import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  TextInput,
} from 'react-admin';
import xlsx from 'xlsx'

function convertToUser({
                         username,
                         role,
                         id,
                         name = '',
                         org_id = 0,
                         system_id = 0,
                         phone = '',
                         email ='',
                       }) {
  return {
    username,
    role,
    id,
    name,
    org_id,
    system_id,
    phone,
    email,
  }
}

const exporter = data => {
  const sdata = data.map(d => convertToUser(d))
  const wb = xlsx.utils.book_new()
  const s = xlsx.utils.json_to_sheet(sdata,
    { header: [
        'username',
        'role',
        'id',
        'name',
        'org_id',
        'system_id',
        'phone',
        'email',
      ]}
    )
  
  xlsx.utils.book_append_sheet(wb, s, 'Sheet 1')
  xlsx.writeFile(wb, `users.xlsx`)
}

const UserList = props => {
  return (
  <List
    {...props}
    filters={[<TextInput source='q' label='ra.action.search' alwaysOn />]}
    exporter={exporter}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="customRoot.users.list.id"/>
      <TextField source="name" label="customRoot.users.list.name"/>
      <TextField source="username" label="customRoot.users.list.username"/>
      <TextField source="role" label="customRoot.users.list.role"/>
      <EmailField source="email" label="customRoot.users.list.email"/>
      <TextField source="phone" label="customRoot.users.list.phone"/>
    </Datagrid>
  </List>
  )
}

export default UserList
