import React from "react"
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  NumberInput,
  Filter,
  TextInput,
  useListController,
  EditButton,
  BooleanField,
  BulkDeleteButton,
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  TopToolbar,
  CreateButton,
  ExportButton,
  DeleteButton,
  usePermissions,
  AutocompleteInput,
  useRecordContext,
  useListContext, useTranslate
} from 'react-admin'
import { VisibilityOff, Visibility, Add, Edit as EditIcon } from '@material-ui/icons'
import styled from "styled-components"
import xlsx from 'xlsx'
import { default as MuiButton } from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'

import ActionToolbar from '../common/ActionToolbar'

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .column-price,
  .column-is_active,
  .column-undefined {
    width: 100px;
  }
`

const ItemsFilter = props => {
  const {permissions} = usePermissions()
  const translate = useTranslate()
  const { filterValues } = props.cprops
  const secondFilter = permissions === "admin" && filterValues
    ? { filter: { org_id: filterValues.org_id }}
    : {}
  return (
    <Filter {...props}>
      {permissions === "admin" &&
        <ReferenceInput label='resources.organizations.singular' reference='organizations' source="org_id" alwaysOn perPage={1000} sort={{ field: 'display_name', order: 'ASC' }}>
           <AutocompleteInput 
            className="autocompeleteMekome"
            label='resources.organizations.singular' 
            optionText='display_name' 
            optionValue="id"
            noOptionsText={translate("customRoot.myMenu.noOptions")}
            matchSuggestion={(filter, choice) => {
              return choice.display_name.toLowerCase().includes(filter.toLowerCase())
            }}
          />
        </ReferenceInput>
      }
      <ReferenceInput label='customRoot.organizations.list.system' reference='systems' source="system_id" alwaysOn {...secondFilter} sort={{ field: 'system_name', order: 'ASC' }}>
        <AutocompleteInput 
          className="autocompeleteMekome"
          label='customRoot.organizations.list.system' 
          optionText='system_name' 
          optionValue="id"
          noOptionsText={translate("customRoot.myMenu.noOptions")}
          matchSuggestion={(filter, choice) => {
            return choice.system_name.toLowerCase().includes(filter.toLowerCase())
          }}
        />
      </ReferenceInput>
    </Filter>
  )
}

const BulkDisableActive = ({ is_active, selectedIds, ...rest }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'items',
    { ids: selectedIds, data: { is_active } },
    {
      onSuccess: () => {
        refresh();
        notify(is_active ? 'פריטים זמין' : 'פריטים לא זמין');
        unselectAll('items');
      },
      onFailure: error => notify('Error: posts not updated', { type: 'warning' }),
    }
  );
  return (
    <Button
      label={`customRoot.items.${is_active ? 'is_active' : 'not_active'}`}
      disabled={loading}
      onClick={updateMany}
    >
      {is_active ? <Visibility /> : <VisibilityOff />}
    </Button>
  )
}

const Bulk = props => (
  <>
    <BulkDisableActive is_active={true} {...props} />
    <BulkDisableActive is_active={false} {...props} />
  </>
)

const CButton = styled(MuiButton)`
  color: #2196f3;
  padding: 2px;
`

const Actions = ({ ...props }) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { filterValues } = useListContext()
  const { org_id, system_id } = filterValues
  const translate = useTranslate()
  
  return (
    <TopToolbar>
      <CButton
        component={Link}
        disabled={!system_id}
        to={{
          pathname: '/order_settings/create',
        }}
        state={{
          org_id,
          system_id
        }}
      >
        <Add />
        {translate('ra.action.create')}
      </CButton>
    </TopToolbar>
  )
}

const Edit = props => {
  const record = useRecordContext();
  const { filterValues = {} } = useListContext()
  const { org_id, system_id } = filterValues
  return (
    <MuiButton
      {...props}
      size="small"
      component={Link}
      to={{
        pathname: `/order_settings/${record.id}`,
      }}
      state={{
        org_id: org_id,
        system_id: system_id,
        id: record.id
      }}
    > 
    <EditIcon/>
    ערוך
    </MuiButton>
  )
}

const Remove = props => {
  const record = useRecordContext()
  const notify = useNotify();
  const navigate = useNavigate();
  const translate = useTranslate()
  const { filterValues = {} } = useListContext()
  const { org_id, system_id } = filterValues

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/order_settings');
  }

  return (
    <DeleteButton {...props} record={{ system_id, org_id, ...record }} mutationOptions={{ onSuccess }}  mutationMode="pessimistic" />
  )
}

const OrderSettingsList = (props) => {
  const controllerProps = useListController(props)
  const {permissions} = usePermissions()

  return (
    <Container>
      <List
        {...props}
        filters={<ItemsFilter permissions={permissions} cprops={controllerProps} />}
        bulkActionButtons={<Bulk />}
        perPage={25}
        actions={<Actions {...props} permissions={permissions} />}
        empty={false}
      >
        <Datagrid>
          <TextField source='id' label='customRoot.order_settings.id' />
          <TextField source='name' label='customRoot.order_settings.name' />
          <TextField source='placeholder' label='customRoot.order_settings.placeholder' />
          <BooleanField source='isRequired' label='customRoot.order_settings.isRequired' />
          <TextField source='sortNumber' label='customRoot.order_settings.sortNumber' />
          <TextField source='fieldType' label='customRoot.order_settings.fieldType' />
          <ActionToolbar>
            <Edit />
            <Remove />
          </ActionToolbar>
        </Datagrid>
      </List>
    </Container>
  )
}

export default OrderSettingsList
