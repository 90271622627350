import React from 'react'
import { Resource } from 'react-admin'

import { PersonAdd, Settings, AddShoppingCart, LocalOffer, People } from '@material-ui/icons'

import { userCreate, userEdit, userList, } from '../components/users'
import { orgCreate, orgEdit, orgList } from '../components/org'
import { orderEdit, orderList } from '../components/orders'
import { ItemCreateOrEdit, itemList } from '../components/items'
import { OrderSettingsList, OrderSettingsCreate, OrderSettingsEdit } from '../components/order_settings'
import { CustomersList, CustomerCreate, CustomerEdit } from '../components/customers'

const getRes = permissions => (
  [
    permissions === "admin"
      ? <Resource options={{ label: "customRoot.myMenu.users" }} name='users' list={userList} edit={userEdit} create={userCreate} icon={PersonAdd} />
      : null,
    permissions === "admin"
      ? <Resource options={{ label: "customRoot.myMenu.organizations" }} name='organizations' list={orgList} edit={orgEdit} create={orgCreate} icon={Settings} />
      : null,
    <Resource options={{ label: "customRoot.myMenu.orders" }} name='orders' list={orderList} edit={orderEdit} icon={AddShoppingCart} />,
    <Resource options={{ label: "customRoot.myMenu.items" }} name='items' list={itemList} edit={ItemCreateOrEdit} create={ItemCreateOrEdit} icon={LocalOffer} />,
    <Resource options={{ label: "customRoot.myMenu.systems" }} name='systems' />,
    <Resource options={{ label: "customRoot.myMenu.order_settings" }} name='order_settings' list={OrderSettingsList} edit={OrderSettingsEdit} create={OrderSettingsCreate} />,
    <Resource options={{ label: "customRoot.myMenu.customers" }} name='customers' list={CustomersList} edit={CustomerEdit} create={CustomerCreate} icon={People} />,
  ]
)

export default getRes
