import React, { useState, useEffect } from 'react'
import { useInput, Button, ImageInput, ImageField, useDataProvider, useTranslate, SimpleForm, TextInput, required, useNotify } from 'react-admin'
import { Snackbar, Grid, Typography, Autocomplete, TextField } from '@mui/material'
import styled from 'styled-components'
import requests from '../../core/requests'
import Alert from '../common/Alert'

const StyledSimpleForm = styled(SimpleForm)`
    & button {
        width: 50%;
        margin-right: 25%;
    };
    flex: 2;
    background-color: #f5f5f5
`

const SImageInput = styled(ImageInput)`
  width: 256px;
  border: 1px dashed #ddd;
`

const ImageWrap = styled.div`
  width: 200px;
  height: 200px;
  padding: 1rem;
  flex: 0 0 200px;
`

const SImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const StyledTypography = styled(Typography)`
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin: 12%;
`

const StyledContainer = styled.div`
    margin: 5%;
    display: flex;
`
const StyledInput = styled.div`
    margin: 4rem;
`

const StyledFormContent = styled.div`
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
`

const StyledContainerInput = styled.div`
    width: 50%
` 

const Image = (props) => {

    const { field: { onChange, value } } = useInput(props)

    const onClick = () => {
        onChange('')
        const settings = { ...props.settings }
        settings.charge_page.logo_link = ''
        props.setSettings(settings)
    }

    if (!value || value === '') return null

    return (
        <div style={{ marginTop: '1rem' }}>
            <ImageWrap>
                <SImage src={value} alt='' />
            </ImageWrap>
            <div>
                <Button label='romove_logo' onClick={onClick} style={{ color: '#cd3636' }} />
            </div>
        </div>
    )
}

const ChargePageSettings = () => {
    const notify = useNotify()
    const dataProvider = useDataProvider()
    const translate = useTranslate()
    const [orgSelected, setOrgSelected] = useState('')
    const [systemSelected, setSystemSelected] = useState('')
    const [organizations, setOrganizations] = useState([])
    const [systems, setSystems] = useState([])
    const [isOpenSnackbar, setOpenSnackbar] = useState(false)
    const [snackbar, setSnackbar] = useState({})
    const [settings, setSettings] = useState({})
    const validateRequired = [required()]

    const openSnackbar = (message, severity) => {
        setOpenSnackbar(true)
        setSnackbar({ message: message, severity: severity })
    }

    const getOrganizations = async () => {
        try {
            const res = await requests.getOrganizations()
            const organizations = res.data.map(org => ({
                id: org.id,
                name: org.display_name
            })).sort((a, b) => a.name.localeCompare(b.name))
            setOrganizations(organizations)
        } catch (error) {
            openSnackbar(error.message, 'error')
        }
    }

    const orgSelectedChanged = async event => {
        try {
            const orgId = event
            setOrgSelected(orgId)
            setSystemSelected(null)
            setSettings({})
            const res = await requests.getSystems(orgId)
            setSystems(res.data.sort((a, b) => a.system_name.localeCompare(b.system_name)))
        } catch (error) {
            openSnackbar(error.message, 'error')
        }
    }

    const getData = (values) => {
        const data = { org_id: orgSelected, system_id: systemSelected }
        for (const key in values) {
            data[key] = values[key] || ''
        }
        return data
    }

    const handleSave = async values => {
        try {
            const data = getData(values)
            const payload = values.file ? data : { data }
            const res = await dataProvider.CREATE('charge_page_settings', payload)
            if (res.data) {
                notify(translate("requestResult.success"), { type: "success" })
            }
            else {
                notify(translate('requestResult.error'), { type: "error" })
            }
        } catch (error) {
            notify(error.message, { type: "error" })
        }
    }

    const closeSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    const getSettings = async event => {
        try {
            const systemId = event
            const res = await requests.getSettings(orgSelected, systemId)
            setSettings(res.data)
        } catch (error) {
            openSnackbar(error.message, 'error')
        }
    }

    useEffect(() => {
        getOrganizations()

    }, [])
    return (
        <StyledContainer>
            <StyledContainerInput>
                <StyledInput style={{marginTop: '0px'}}>
                <Autocomplete
                      labelId='orgs-select-label'
                      options={organizations}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label={translate('customRoot.upload.org')} />}
                      onChange={(event, value) => { 
                        orgSelectedChanged(value.id)
                      }}
                    />
                </StyledInput>
                <StyledInput>
                <Autocomplete
                      labelId='orgs-select-label'
                      options={systems}
                      getOptionLabel={(option) => option.system_name}
                      renderInput={(params) => <TextField {...params} source="system_name" label={translate('customRoot.upload.sys')} />}
                      onChange={(event, value) => {
                        setSystemSelected(value.system_id)
                        getSettings(value.system_id)
                      }}
                    />
                </StyledInput>
            </StyledContainerInput>
            <StyledSimpleForm onSubmit={handleSave} record={
                {
                    title_text: settings.charge_page?.title_text || '',
                    body_text: settings.charge_page?.body_text || '',
                    logo_link: settings.charge_page?.logo_link || '',
                    business_address: settings.charge_page?.business_address || '',
                    business_phone: settings.charge_page?.business_phone || ''
                }}>
                <StyledFormContent>
                    <StyledTypography>
                        {translate('customRoot.myMenu.settings_charge_page')}
                    </StyledTypography>
                    <Grid container spacing={1} style={{ justifyContent: 'center' }}>
                        <TextInput disabled={!systemSelected} fullWidth source='title_text' label='title' />
                        <TextInput disabled={!systemSelected} fullWidth multiline source='body_text' label='content' />
                        {!(settings.charge_page?.logo_link) &&
                            <SImageInput
                                source='file'
                                accept='image/*'
                                label='select_logo'>
                                <ImageField source='src' />
                            </SImageInput>}
                        {settings.charge_page?.logo_link && <Image source='logo_link' setSettings={setSettings} settings={settings} />}
                        <TextInput disabled={!systemSelected} fullWidth source='business_address' validate={validateRequired} label='ra.credit_card_details.business_address' />
                        <TextInput disabled={!systemSelected} fullWidth source='business_phone' validate={validateRequired} label='ra.credit_card_details.business_phone' />
                    </Grid>
                </StyledFormContent>
            </StyledSimpleForm>
            <Snackbar open={isOpenSnackbar} autoHideDuration={6000} onClose={closeSnackbarClose}>
                <Alert onClose={closeSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </StyledContainer>
    )

}

export default ChargePageSettings
